<template>
  <div>
    <hr
      class="hr-text"
      data-content="Danh mục sản phẩm"
      style="font-weight: 600"
    />
    <Categories
      @category-selected="handleCategorySelected"
      :categories="stockCheckSheet.categories"
    />
    <hr class="hr-text" :data-content="dataContent" style="font-weight: 600" />
    <div class="mb-5">
      <b-row class="mb-3">
        <b-col md="3">
          <b-form-select
            v-model="selectTypeSearch"
            class="select-style"
            :options="listTypeSearch"
            size="sm"
            value-field="id"
            text-field="name"
            @change="changePlaceholder"
          ></b-form-select>
        </b-col>
        <b-col md="6">
          <b-form-input
            size="sm"
            v-model="textSearchProduct"
            @input="onInputChangeProduct"
            class="autosuggest__input"
            @keyup.enter="fetchProductByCategory()"
            :placeholder="inputPlaceHolder"
          >
            <template slot-scope="{ suggestion }">
              <div>{{ suggestion.item.productName }}</div>
            </template>
          </b-form-input>
        </b-col>
        <b-col md="3">
          <b-form-select
            v-model="selectTypeFilter"
            class="select-style"
            :options="listTypeFilter"
            size="sm"
            value-field="id"
            text-field="name"
            @change="handleChangeSelectTypeFilter"
          ></b-form-select>
        </b-col>
      </b-row>
    </div>
    <b-row class="mb-5">
      <b-col> </b-col>
      <b-col>
        <div class="d-flex d-inline justify-content-end mb-4">
          <span class="mr-2">
            <i class="fas fa-circle fa-sm text-dark"></i>
            Tồn có thể bán
          </span>
          <span class="mr-2">
            <i class="fas fa-circle fa-sm text-warning"></i>
            Đang giữ hàng
          </span>
          <span class="mr-2">
            <i class="fas fa-circle fa-sm text-primary"></i>
            Đang bảo hành
          </span>
        </div>
      </b-col>
    </b-row>
    <b-table ref="top" :items="items" :fields="fieldsComputed" :busy="isBusy">
      <template v-slot:cell(no)="data">{{
        setIndexTable(data.item.index)
      }}</template>
      <template v-slot:cell(barCode)="data">
        <div v-if="data.item.barCode !== ''">
          <b v-text="data.item.barCode"></b>
          <p class="productCode m-0">
            <span v-text="data.item.productName"></span>
          </p>
        </div>
      </template>
      <template v-slot:cell(imei)="data">
        <b-form-textarea
          v-if="data.item.productType === PRODUCT_TYPE.PRODUCT_IMEI"
          :disabled="data.item.productType !== PRODUCT_TYPE.PRODUCT_IMEI"
          style="border-color: #007bff"
          size="sm"
          v-model="data.item.realImeiNos"
          @keyup="enterClicked(data.index, data.item.realImeiNos)"
          class="w-100 h-90"
        ></b-form-textarea>
      </template>
      <template v-slot:cell(stockInfo)="data">
        <div
          style="text-align: center"
          v-if="checkPermissions(['STOCK_CHECK_VIEW_DETAIL'])"
        >
          <b
            v-text="data.item.inStockQuantity"
            class="mr-1"
            v-b-tooltip.hover.bottom
            title="Tổng tồn có thể bán"
          ></b>
          <b
            class="text-primary mr-1 font-size-setting"
            v-if="data.item.warrantyQuantity > 0"
            v-b-tooltip.hover.bottom
            title="Tổng đang bảo hành"
          >
            {{ '+ ' + data.item.warrantyQuantity }}
          </b>
          <b
            class="text-warning font-size-setting"
            v-if="data.item.holdingQuantity > 0"
            v-b-tooltip.hover.bottom
            title="Tổng đang giữ hàng"
          >
            {{ '+ ' + data.item.holdingQuantity }}
          </b>
        </div>
      </template>
      <template v-slot:cell(realQuantity)="data">
        <b-form-input
          v-if="!isConfirmed"
          v-model="data.item.realQuantity"
          :disabled="data.item.productType === PRODUCT_TYPE.PRODUCT_IMEI"
          type="number"
          min="0"
        >
        </b-form-input>
        <span v-else>{{ data.item.realQuantity }}</span>
      </template>
      <template v-slot:cell(stateName)="data">
        <span
          :class="checkClassStateName(data.item.stateName)"
          class="font-weight-bold"
        >
          {{ data.item.stateName }}
          <span v-if="data.item.stockInfo">{{ data.item.stockInfo }}</span>
        </span>
      </template>
      <template v-slot:cell(imeiNos)="data">
        <span
          v-for="imeiNo in data.item.imeiNos"
          :key="imeiNo"
          :class="getClassForImei(imeiNo, data.item.realImeiNos)"
        >
          {{ imeiNo }}
          <br />
        </span>
      </template>
      <template v-slot:cell(action)="data">
        <div class="d-flex justify-content-center">
          <b-dropdown size="sm" id="dropdown-left" no-caret right>
            <template slot="button-content">
              <i
                style="font-size: 1rem; padding-right: 0px"
                class="flaticon2-settings"
              ></i>
            </template>
            <b-dropdown-item
              v-if="!isConfirmed"
              @click="upsertStockCheckProduct(data.item)"
            >
              <span style="color: #3f4254; font-size: 12px">
                <i style="font-size: 1rem" class="flaticon2-plus"></i>
                &nbsp; Thêm sản phẩm
              </span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="checkPermissions(['STOCK_CHECK_VIEW_DETAIL'])"
              @click="showHistoryModal(data.item.id)"
            >
              <span style="color: #3f4254; font-size: 12px">
                <i class="flaticon-eye"></i>
                &nbsp; Lịch sử
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <b-row class="d-flex justity-content-center">
      <b-col>
        <p class="mt-3 text-dark" style="font-weight: 500">
          Tổng số :
          {{ totalItem }}
        </p>
      </b-col>
      <b-col>
        <BPaginationNavCustom
          :number-of-pages="numberOfPage || 1"
          :current-page="currentPage"
          :total-page="numberOfPage"
          :linkParams="{ id: this.stockCheckSheet.id }"
          @page-change="fetchProductByCategory()"
        >
        </BPaginationNavCustom>
      </b-col>
    </b-row>
    <hr />
    <div>
      <div>
        <slot> </slot>
      </div>
    </div>
    <template>
      <InventoryCheckHistory
        ref="inventory-check-history"
        :stockCheckProductLogs="stockCheckProductLogs"
      />
    </template>
  </div>
</template>

<script>
import {
  STOCK_CHECK_SHEET_STATUS,
  PRODUCT_SEARCH_TYPE,
  PRODUCT_TYPE,
  STOCK_CHECK_PRODUCT_LOG_SOURCE_TYPE,
} from '@/utils/enum';
import ApiService from '@/core/services/api.service';
import { TIME_TRIGGER } from '@/utils/constants';
import Categories from '@/view/components/inventoryCheck/Categories.vue';
import { makeToastSuccess } from '@/utils/common';
import { checkPermissions, checkPermission } from '@/utils/saveDataToLocal';
import debounce from 'lodash/debounce';
import { handleError } from '../../../utils/common';
import InventoryCheckHistory from '@/view/components/inventoryCheck/inventory-history-modal.vue';
import BPaginationNavCustom from '@/view/base/bootstrap/BPaginationNavCustom.vue';

export default {
  props: {
    stockCheckSheetData: {
      type: Object,
    },
  },
  watch: {
    $props: {
      handler(newVal) {
        this.stockCheckSheet = newVal.stockCheckSheetData;
        this.isConfirmed =
          this.stockCheckSheet.status !== STOCK_CHECK_SHEET_STATUS.NEW;
        this.selectedCategoryId = this.stockCheckSheet.categories[0].id;
        this.fetchProductByCategory();
        this.handleSearchType(this.stockCheckSheet.productType);
        this.configureFieldsForPermission();
      },
      deep: true,
      immediate: false,
    },
  },
  data() {
    return {
      PRODUCT_TYPE,
      STOCK_CHECK_SHEET_STATUS,
      PRODUCT_SEARCH_TYPE,
      items: [],
      textSearchProduct: '',
      inputPlaceHolder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
      dataContent: 'Danh sách sản phẩm',
      selectTypeSearch: PRODUCT_SEARCH_TYPE.PRODUCT_NAME,
      selectTypeFilter: 'ASC',
      linkGen: (pageNum) => {
        return pageNum === 1
          ? `?id=${this.stockCheckSheet.id}`
          : `?id=${this.stockCheckSheet.id}&page=${pageNum}`;
      },
      listTypeSearch: [
        {
          id: PRODUCT_SEARCH_TYPE.PRODUCT_NAME,
          name: 'Tìm sản phẩm',
        },
        {
          id: PRODUCT_SEARCH_TYPE.IMEI_CODE,
          name: 'Tìm sản phẩm IMEI',
        },
      ],
      listTypeFilter: [
        {
          id: 'DESC',
          name: 'Sắp xếp theo: kiểm kê đủ',
        },
        {
          id: 'ASC',
          name: 'Sắp xếp theo: kiểm kê thiếu',
        },
      ],
      totalItem: 0,
      limit: 10,
      numberOfPage: 1,
      currentPage: this.$route.query.page || 1,
      stockCheckSheet: { status: 1, categories: [] }, // default
      searchProduct: '',
      searchProductImei: '',
      fields: [
        {
          key: 'no',
          label: 'STT',
          tdClass: 'text-center align-middle',
          thClass: 'text-center align-middle',
          thStyle: { width: '5%' },
        },
        {
          key: 'barCode',
          label: 'Sản phẩm',
          sortable: false,
          thStyle: { width: '30%' },
          tdClass: 'text-left align-middle',
          thClass: 'text-center align-middle',
        },
        {
          key: 'imei',
          label: 'IMEI',
          sortable: false,
          thStyle: { width: '20%' },
          tdClass: 'text-left align-middle',
          thClass: 'text-center align-middle',
        },
        {
          key: 'imeiNos',
          label: 'IMEI hệ thống',
          sortable: false,
          thStyle: { width: '15%' },
          tdClass: 'text-left align-middle',
          thClass: 'text-center align-middle',
        },
        {
          key: 'stockInfo',
          label: 'Tồn kho',
          tdClass: 'text-center align-middle',
          thClass: 'text-center align-middle',
          thStyle: { width: '10%' },
        },
        {
          key: 'realQuantity',
          label: 'Số lượng',
          thClass: 'text-center align-middle',
          tdClass: 'text-right align-middle',
          thStyle: { width: '10%' },
        },
        {
          key: 'stateName',
          label: 'Tình trạng',
          thClass: 'text-center align-middle',
          thStyle: { width: '10%' },
          tdStyle: {
            fontSize: '1.5rem',
          },
        },
        {
          key: 'action',
          label: '',
          tdClass: 'text-center align-middle',
          thClass: 'text-center align-middle',
        },
      ],
      selectedCategoryId: null,
      isBusy: false,
      isConfirmed: true,
      stockCheckProductLogs: [],
    };
  },
  methods: {
    checkPermissions,
    checkPermission,
    async showHistoryModal(id) {
      try {
        const {
          data: { data },
        } = await ApiService.query(`v2/stock-check-product/history/${id}`);
        this.stockCheckProductLogs = data;
        this.$refs['inventory-check-history'].showModal();
      } catch (err) {
        this.handleError(err);
      }
    },
    handleChangeSelectTypeFilter(item) {
      this.selectTypeFilter = item;
      this.fetchProductByCategory();
    },
    handleSearchType(productType) {
      if (
        [PRODUCT_TYPE.PRODUCT_IMEI, PRODUCT_TYPE.PRODUCT].includes(productType)
      ) {
        this.listTypeSearch = this.listTypeSearch.filter(
          (searchType) => searchType.id === productType,
        );
        this.selectTypeSearch = productType;
      }
      this.changePlaceholder();
    },
    //TODO
    configureFieldsForPermission() {
      const hasPermissionViewDetail = checkPermission(
        'STOCK_CHECK_VIEW_DETAIL',
      );
      if (
        !hasPermissionViewDetail ||
        this.stockCheckSheetData.productType !== PRODUCT_TYPE.PRODUCT_IMEI
      ) {
        this.fields = this.fields.filter((field) => field.key !== 'imeiNos');
      }
    },
    enterClicked(index, value) {
      let countLine = 0;
      const tmp = value.split(/\r\n|\r|\n/);
      tmp.forEach((element) => {
        if (element.trim() !== '') {
          countLine++;
        }
      });
      this.items[index].realQuantity = countLine;
    },
    setIndexTable(index) {
      const currentPage = this.$route.query.page || 1;
      return (currentPage - 1) * this.limit + index;
    },
    backToProductByCate() {
      this.$router
        .push({
          name: 'upsert-inventory-check',
          query: { id: this.stockCheckSheet.id },
        })
        .catch(() => {});
      this.fetchProductByCategory();
    },
    checkClassStateName(stateName) {
      if (stateName === 'Đủ') {
        return 'badge badge-success';
      }
      if (stateName === 'Lệch') {
        return 'text-danger';
      }
      if (stateName === 'Mới') {
        return 'badge badge-light';
      }
      if (stateName === 'SP bên ngoài') {
        return 'badge badge-warning';
      }
    },
    mappingStockCheckProductModel(product) {
      const realImeiNos =
        product.productType === PRODUCT_TYPE.PRODUCT_IMEI
          ? product.realImeiNos.split(/\r\n|\r|\n|,/).map((item) => item.trim())
          : [];
      return {
        id: product?.id,
        productId: product.productId,
        stockCheckSheetId: this.stockCheckSheet.id,
        stockCheckCategoryId: this.selectedCategoryId,
        realImeiNos,
        realQuantity: Number(product.realQuantity),
        version: product.version || 0,
        productOutside: product.productOutside,
      };
    },
    async upsertStockCheckProduct(product) {
      try {
        const body = {
          ...this.mappingStockCheckProductModel(product),
          sourceType: STOCK_CHECK_PRODUCT_LOG_SOURCE_TYPE.WEB_MANUAL,
        };
        const {
          data: { data },
          message,
        } = await ApiService.put(
          '/v2/stock-check-product/add-product-to-cart',
          body,
        );
        const foundRowUpdated = this.items.find(({ id }) => id === data.id);
        if (foundRowUpdated) {
          foundRowUpdated.version = data.version;
          foundRowUpdated.realQuantity = data.realQuantity;
          foundRowUpdated.realImeiNos = this.convertImeiToString(
            data.realImeiNos,
          );
          foundRowUpdated.stateName = data.stateName;
          foundRowUpdated.stockInfo = data.stockInfo;
        }
        makeToastSuccess(message);
      } catch (error) {
        handleError(error);
        const { status } = error.response?.data;
        if (status === 409) {
          this.retryFetchOnConflict();
        }
      }
    },
    retryFetchOnConflict() {
      this.fetchProductByCategory();
    },
    handleCategorySelected(categoryId) {
      if (this.isBusy) {
        return;
      }
      this.$router
        .push({
          name: 'upsert-inventory-check',
          query: { id: this.stockCheckSheet.id },
        })
        .catch(() => {});
      this.selectedCategoryId = categoryId;
      this.$route.query.page = 1;
      this.fetchProductByCategory();
    },
    changePlaceholder() {
      if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.PRODUCT_NAME) {
        this.searchProductImei = '';
        this.inputPlaceHolder = 'Tìm kiếm theo tên, mã, mã vạch sản phẩm';
      } else if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.IMEI_CODE) {
        this.searchProduct = '';
        this.inputPlaceHolder = 'Tìm kiếm theo mã IMEI sản phẩm';
      }
    },
    handleSearchByProductType() {
      if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.PRODUCT_NAME) {
        this.searchProduct = this.textSearchProduct;
        this.searchProductImei = '';
      } else {
        this.searchProductImei = this.textSearchProduct;
        this.searchProduct = '';
      }
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'upsert-inventory-check',
      });
      this.fetchProductByCategory();
    },
    getParamsFilter() {
      this.handleSearchByProductType();
      const params = {
        stockCheckCategoryId: this.selectedCategoryId,
        productType: this.stockCheckSheet.productType,
        limit: this.limit,
        page: this.$route.query.page,
        storeId: this.stockCheckSheet.storeId,
        searchImei: this.searchProductImei,
        searchProduct: this.searchProduct,
        orderBy: this.selectTypeFilter,
      };
      return params;
    },
    fetchProductByCategory: async function () {
      try {
        this.dataContent = 'Danh sách sản phẩm';
        this.isBusy = true;
        const params = this.getParamsFilter();
        const response = await ApiService.query('/v2/stock-check-product', {
          params,
        });
        const data = response.data?.data || [];
        this.totalItem = data.totalRow || 0;
        this.numberOfPage = data.totalPage || 1;
        const isScanImei =
          this.totalItem === 1 &&
          this.selectTypeSearch === PRODUCT_TYPE.PRODUCT_IMEI &&
          !this.isConfirmed;
        if (isScanImei) {
          this.textSearchProduct = '';
          const stockCheckProduct = data.dataset[0];
          const foundImei = stockCheckProduct.imeiNos?.find(
            (imeiNo) => imeiNo === this.searchProductImei,
          );
          if (foundImei) {
            const realImeiNos = stockCheckProduct.realImeiNos
              .concat(foundImei)
              .join(', ');
            stockCheckProduct.realImeiNos = realImeiNos;
            await this.upsertStockCheckProduct(stockCheckProduct);
          }
        }
        if (!isScanImei) {
          this.items = this.mappingStockCheckProductRes(data.dataset);
        }
      } catch (err) {
        handleError(err);
      } finally {
        this.isBusy = false;
      }
    },
    onInputChangeProduct() {
      this.debounceInputProduct();
    },
    debounceInputProduct: debounce(function () {
      this.fetchProductByCategory();
    }, TIME_TRIGGER),
    mappingStockCheckProductRes(stockCheckProducts = []) {
      return stockCheckProducts.map((product, index) => {
        return {
          index: index + 1,
          ...product,
          realQuantity: product.realQuantity || 0,
          productType: product.productType,
          createdBy: product.createdBy || '',
          realImeiNos: this.convertImeiToString(product.realImeiNos),
        };
      });
    },
    convertImeiToString(realImeiNos) {
      return realImeiNos ? realImeiNos.join(', \n') : '';
    },
    getClassForImei(imeiNo, realImeiNos) {
      return realImeiNos.includes(imeiNo) ? 'text-success' : 'text-danger';
    },
  },
  components: {
    Categories,
    InventoryCheckHistory,
    BPaginationNavCustom,
  },
  computed: {
    fieldsComputed() {
      return this.fields;
    },
  },
};
</script>

<style scoped>
.scrollable-cell {
  height: 100px;
  overflow-y: auto;
}

.item {
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.btn-cart {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 30px;
}

.cursor-pointer {
  cursor: pointer;
}

.icon-style {
  font-size: 22px;
  color: #3699ff;
}
</style>
