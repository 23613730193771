<template>
  <b-modal
    ref="inventory-check-history"
    title="Lịch sử cập nhật"
    id="inventory-check-history"
    no-close-on-backdrop
    size="xl"
  >
    <b-table
      caption-top
      responsive
      bordered
      hover
      :fields="fields"
      :items="stockCheckProductLogs"
      id="my-table"
    >
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(updatedAt)="data">
        {{ formatDate(data.item.updatedAt, CLIENT_DATE_TIME) }}
      </template>
      <template #cell(sourceType)="data">
        {{ getSourceTypeName(data.item.sourceType) }}
      </template>

      <template #cell(detail)="data">
        <b-table
          small
          :items="Object.entries(data.item.changes)"
          :fields="changeFields"
          responsive
          bordered
          hover
        >
          <template #cell(field)="fieldData">
            {{ fieldNameMap[fieldData.item[0]] || fieldData.item[0] }}
          </template>
          <template #cell(oldValue)="fieldData">
            {{ fieldData.item[1].oldValue }}
          </template>
          <template #cell(newValue)="fieldData">
            {{ fieldData.item[1].newValue }}
          </template>
        </b-table>
      </template>
    </b-table>
    <template #modal-footer>
      <b-button variant="secondary" @click="hideModal">Đóng</b-button>
    </template>
  </b-modal>
</template>

<script>
import { formatDate, CLIENT_DATE_TIME } from '@/utils/date';
import { STOCK_CHECK_PRODUCT_LOG_SOURCE_TYPE_NAME } from '@/utils/enum-name';
export default {
  name: 'InentoryHistoryModal',
  props: {
    stockCheckProductLogs: {
      required: true,
    },
  },
  watch: {
    stockCheckProductLogs(newVal) {
      this.stockCheckProductLogs = newVal;
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'index',
          label: 'STT',
          thStyle: { width: '2%' },
          tdClass: 'text-center',
        },
        {
          key: 'actionName',
          label: 'Hành động',
          tdClass: 'font-weight-bold',
          thStyle: { width: '8%' },
        },
        {
          key: 'updatedByName',
          label: 'Người cập nhật',
          thStyle: { width: '13%' },
          tdClass: 'font-weight-bold',
        },
        {
          key: 'sourceType',
          label: 'Nguồn',
          thStyle: { width: '10%' },
          tdClass: 'font-weight-bold',
        },
        {
          key: 'updatedAt',
          sortable: true,
          label: 'Thời gian',
          tdClass: 'font-weight-bold',
          thStyle: { width: '10%' },
        },
        {
          key: 'detail',
          label: 'Chi tiết',
          tdClass: 'text-center',
        },
      ],
      changeFields: [
        { key: 'field', label: 'Thuộc tính', thStyle: { width: '20%' } },
        { key: 'oldValue', label: 'Giá trị cũ', thStyle: { width: '40%' } },
        { key: 'newValue', label: 'Giá trị mới', thStyle: { width: '40%' } },
      ],
      CLIENT_DATE_TIME: CLIENT_DATE_TIME,
      fieldNameMap: {
        realImeiNos: 'Danh sách IMEI',
        realQuantity: 'Số lượng',
        note: 'Ghi chú',
      },
    };
  },
  methods: {
    formatDate,
    showModal() {
      this.$refs['inventory-check-history'].show();
    },
    hideModal() {
      this.$refs['inventory-check-history'].hide();
    },
    getSourceTypeName(sourceType) {
      return STOCK_CHECK_PRODUCT_LOG_SOURCE_TYPE_NAME[sourceType];
    },
  },
};
</script>

<style lang="scss">
.inventory-check-history {
  background-color: aqua;
  table.b-table[aria-busy='true'] {
    opacity: 0.6;
  }
  .modal-dialog.modal-xl {
    max-width: 95vw;
  }
}
</style>
